import cx from "classnames"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import styles from "./columns-image-cards.module.css"
import BlockHeader from "@/common/partials/block-header/block-header"

export default function ColumnsImageCards({ block }) {
  const { attrs, innerBlocks } = block
  if (innerBlocks.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      width="full"
      gap={attrs.blockGap ?? "normal"}
      className={cx(styles.wrapper, {
        "has-white-cards": attrs.theme === "white"
      })}>
      <BlockHeader
        heading={block?.attrs?.heading}
        text={block?.attrs?.text}
        align={block?.attrs?.align}
      />
      <div className={cx(styles["layout-" + attrs.layout])}>
        <Blocks blocks={innerBlocks} />
      </div>
    </Block>
  )
}
